import { Link } from "react-router-dom";
import classNames from "classnames";
import useState from "react-usestateref";
import { useEffect } from "react";
import moment from "moment";
import { getProgramInfoImage } from "../../services/apiCore";
import defaultImage from "../../assets/images/Placeholder.png";
import { Col, Container, Row, Badge, Nav } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ProgramPost = ({ program }) => {
  const [programImage, setProgramImage] = useState();
  const [maxPerson, setmaxPerson] = useState(0);
  const [programFees, setprogramFees] = useState();
  const [showMore, setShowMore] = useState(false);

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const handleWindowResize = (event) => {
    setWindowSize(window.innerWidth);
    setIsMobile(window.innerWidth < 992);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    let tempvalue = 0;
    program?.programSessions?.map((item) => {
      tempvalue = tempvalue + item.maximunNumberOfPaticipant;
    });
    setmaxPerson(tempvalue);
    let programFeesStr = "";
    if (program?.isFree === false) {
      program?.programFees?.map((item) => {
        if (item.memberType) {
          programFeesStr =
            programFeesStr +
            item.memberType?.nameZH +
            "$" +
            item.amount +
            ";  ";
        } else {
          programFeesStr = programFeesStr + "非會員$" + item.amount + ";  ";
        }
      });
    } else if (program?.isFree === true) {
      programFeesStr = "免費";
    } else {
      programFeesStr = "";
    }
    setprogramFees(programFeesStr);

    setProgramImage("");
    // get image
    const getImg = async () => {
      const img = await getProgramInfoImage(program?.id);
      setProgramImage(img?.data);
    };
    getImg();
  }, [program]);

  return (
    <>
      {!!program && (
        <Row className="mb-3 me-1">
          <Col xs={12} lg={6}>
            <div className="text-center">
              <Link to={"/program/detail/" + program?.id}>
                {/* <div className="program-image-container"> */}
                <img
                  src={programImage || defaultImage}
                  alt="Program Image"
                  className="program-post-image2 img-fluid d-block shadow rounded"
                ></img>
                {/* </div> */}
              </Link>
            </div>
          </Col>
          <Col xs={12} lg={6} className={isMobile ? "ps-4" : "ps-2"}>
            <Row>
              <h4 className="fw-semibold mt-1">
                <Link
                  to={"/program/detail/" + program?.id}
                  //state={{ id: program?.id }}
                >
                  {program?.name}
                </Link>
              </h4>
            </Row>
            <Row>
              <div className="mt-3">
                <Link to="#">
                  <span className="px-1">
                    <Badge
                      bg=""
                      className={classNames("badge-soft-info", "p-2")}
                    >
                      {
                        program?.programOnlineEnrollmentSetting?.category
                          ?.data1ZH
                      }
                    </Badge>
                  </span>
                  {program?.isFree ? (
                    <span className="px-1">
                      <Badge
                        bg=""
                        className={classNames("badge-soft-success", "p-2")}
                      >
                        免費
                      </Badge>
                    </span>
                  ) : (
                    <span className="px-1">
                      <Badge
                        bg=""
                        className={classNames("badge-soft-primary", "p-2")}
                      >
                        收費
                      </Badge>
                    </span>
                  )}
                  <span className="px-1">
                    <Badge
                      bg=""
                      className={classNames("badge-soft-orange", "p-2")}
                    >
                      {program?.center.nameZH}
                    </Badge>
                  </span>
                </Link>
              </div>
            </Row>
            <Row>
              <Col xs={2} lg={2}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      <strong>活動日期</strong>
                    </Tooltip>
                  }
                >
                  <div>
                    <FeatherIcon
                      icon="calendar"
                      size="35"
                      className="px-2 pb-2"
                    />
                  </div>
                </OverlayTrigger>
              </Col>
              <Col xs={10} lg={10}>
                <Row>
                  <p className=" mt-1">
                    {moment(program?.startDate).format(
                      process.env.REACT_APP_DATE_FORMAT
                    )}{" "}
                  </p>
                </Row>
                <Row>
                  <p className="text-muted mt-1">
                    {moment(program?.endDate).format(
                      process.env.REACT_APP_DATE_FORMAT
                    )}{" "}
                  </p>
                </Row>
              </Col>
              {/*<Col xs={12} lg={5}>
                 <Row>
                  <Col xs={2} lg={4}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <strong>堂數</strong>
                        </Tooltip>
                      }
                    >
                      <p className="text-muted mt-1">
                        {" "}
                        <FeatherIcon
                          icon="grid"
                          size="35"
                          className="px-2 pb-1 pointer"
                        />
                      </p>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={10} lg={6} className="pt-2">
                    {program?.programSessions?.length} 節
                  </Col>
                </Row>
              </Col> */}
            </Row>

            <Row>
              <Col xs={2} lg={2}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      <strong>堂數</strong>
                    </Tooltip>
                  }
                >
                  <p className="text-muted mt-1">
                    {" "}
                    <FeatherIcon
                      icon="grid"
                      size="35"
                      className="px-2 pb-1 pointer"
                    />
                  </p>
                </OverlayTrigger>
              </Col>
              <Col xs={10} lg={10} className="pt-2">
                {program?.programSessions?.length} 節
              </Col>
            </Row>

            {showMore && (
              <>
                <Row>
                  <Col xs={2} lg={2}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <strong>活動時間</strong>
                        </Tooltip>
                      }
                    >
                      <div>
                        <FeatherIcon
                          icon="clock"
                          size="35"
                          className="px-2 pb-2 pointer"
                        />
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={10} lg={10}>
                    <p className="text-muted mt-1">
                      {moment(program?.startDate).format(
                        process.env.REACT_APP_TIME_FORMAT
                      )}
                      {"-"}
                      {moment(program?.endDate).format(
                        process.env.REACT_APP_TIME_FORMAT
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} lg={2}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <strong>活動場地</strong>
                        </Tooltip>
                      }
                    >
                      <div>
                        <FeatherIcon
                          icon="map-pin"
                          size="35"
                          className="px-2 pb-2 pointer"
                        />
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={10} lg={10}>
                    {" "}
                    <p className="text-muted mt-1">{program?.venue}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} lg={2}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <strong>活動對象</strong>
                        </Tooltip>
                      }
                    >
                      <div>
                        <FeatherIcon
                          icon="users"
                          size="35"
                          className="px-2 pb-2 pointer"
                        />
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={10} lg={10}>
                    <p className="text-muted mt-1"></p>
                    {program?.programObject}
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} lg={2}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <strong>活動人數</strong>
                        </Tooltip>
                      }
                    >
                      <div>
                        <FeatherIcon
                          icon="user-check"
                          size="35"
                          className="px-2 pb-2 pointer"
                        />
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={10} lg={10}>
                    {" "}
                    <p className="text-muted mt-1">
                      {program?.programOnlineEnrollmentSetting?.enrollmentQuota}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} lg={2}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <strong>費用</strong>
                        </Tooltip>
                      }
                    >
                      <div>
                        <FeatherIcon
                          icon="dollar-sign"
                          size="35"
                          className="px-2 pb-2 pointer"
                        />
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={10} lg={10}>
                    {" "}
                    <p className="text-muted mt-1">{programFees}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} lg={2}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <strong>備註</strong>
                        </Tooltip>
                      }
                    >
                      <div>
                        <FeatherIcon
                          icon="more-vertical"
                          size="35"
                          className="px-2 pb-2 pointer"
                        />
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={10} lg={10}>
                    <p className="text-muted mt-1">
                      {program?.introduction?.length > 50
                        ? program?.introduction.substring(0, 50) + "..."
                        : program?.introduction}{" "}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={2} lg={2}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <strong>注意事項</strong>
                        </Tooltip>
                      }
                    >
                      <div>
                        <FeatherIcon
                          icon="more-horizontal"
                          size="35"
                          className="px-2 pb-2 pointer"
                        />
                      </div>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={10} lg={10}>
                    {" "}
                    <p className="text-muted mt-1">
                      {" "}
                      {program?.reminder?.length > 50
                        ? program?.reminder.substring(0, 50) + "..."
                        : program?.reminder}{" "}
                    </p>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <Nav
                  className="ps-2 cursor-pointer"
                  onClick={() => setShowMore(!showMore)}
                >
                  {!showMore ? "更多..." : "...收起"}
                </Nav>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProgramPost;
