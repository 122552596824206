import React, { useEffect } from "react";
import Hero9 from "../../components/heros/hero9";
import About from "./About";
import Footer from "../../components/footer/Footer";
import BackToTop from "../../components/BackToTop";
import Navbar4 from "../../components/navbars/Navbar4";
import PublicProgram from "../Program/PublicProgram";
import useState from "react-usestateref";
import Question from "../../components/Question";
import Banner from "../../components/Banner";

const Home = () => {
  // const [content, setContent, contentRef] = useState();
  // useEffect(() => {
  //   fetch("https://service.elchk.org.hk/")
  //     .then((response) => response.text())
  //     .then((data) => {
  //       setContent(data);
  //       console.log("data", data);
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  return (
    <>
      <div>
        <Navbar4 fixedWidth />
      </div>

      <div className="top-menu-placeholder"></div>
      <div className="position-relative bg-gradient2">
        <Banner />
      </div>
      <section className="position-relative p-3 bg-gradient2">
        <PublicProgram showTag />
      </section>

      {/*  <section className="position-relative p-3 bg-gradient2">
                <About />
            </section> 
      */}
      <section className="position-relative p-3 bg-gradient2">
        <Footer />
        <Question />
        <BackToTop />
      </section>
    </>
  );
};

export default Home;
