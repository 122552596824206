import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

// component
import Navbar4 from "../../../../components/navbars/Navbar4";
import Footer from "../../../../components/footer/Footer";

import AccountInformation from "./AccountInformation";
import Center from "./center";
import ResetPassword from "./ResetPassword";
import Notifications from "./Notifications";
import Program from "../../../Program";
import ProgramEnrollment from "./ProgramEnrollment";
import PaymentHistory from "./PaymentHistory";
import CenterInfo from "./CenterInfo";
import { useState } from "react";
import { useEffect } from "react";

const Settings = ({ activityKey }) => {
  const [actKey, setActKey] = useState(activityKey ? activityKey : "account");
  // const actKey = activityKey ? activityKey : "account"
  useEffect(() => {
    setActKey(activityKey ? activityKey : "account");
  }, [activityKey]);

  return (
    <>
      {/* header */}
      <Navbar4 fixedWidth />

      <div className="top-menu-placeholder"></div>
      <section className="position-relative p-3 bg-gradient2">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="page-title">
                <h3 className="my-0">賬戶設定</h3>
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <Tab.Container activeKey={actKey}>
                    <Row>
                      <Col lg={3}>
                        <Nav
                          as="ul"
                          variant="pills"
                          className="navtab-bg flex-column"
                        >
                          <Nav.Item as="li">
                            <Nav.Link
                              as={Link}
                              to="/other/account/account"
                              eventKey="account"
                              onClick={() => setActKey("account")}
                            >
                              <span>個人資料</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link
                              as={Link}
                              to="/other/account/center"
                              eventKey="center"
                              onClick={() => setActKey("center")}
                            >
                              <span>中心</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link
                              as={Link}
                              to="/other/account/programEnrollment"
                              eventKey="programEnrollment"
                              onClick={() => setActKey("programEnrollment")}
                            >
                              <span>活動記錄</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link
                              as={Link}
                              to="/other/account/paymentHistory"
                              eventKey="paymentHistory"
                              onClick={() => setActKey("paymentHistory")}
                            >
                              <span>付款記錄</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li" className="my-2">
                            <Nav.Link
                              as={Link}
                              to="/other/account/password"
                              eventKey="password"
                              onClick={() => setActKey("password")}
                            >
                              <span>密碼</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link
                              as={Link}
                              to="/other/account/centerInfo"
                              eventKey="centerInfo"
                              onClick={() => setActKey("centerInfo")}
                            >
                              <span>中心資訊</span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col lg={9}>
                        <Tab.Content className="p-0">
                          <Tab.Pane
                            eventKey="account"
                            transition
                            className="px-3"
                          >
                            <AccountInformation />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="center"
                            transition
                            className="px-3"
                          >
                            <Center />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="programEnrollment"
                            transition
                            className="px-3"
                          >
                            <ProgramEnrollment />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="paymentHistory"
                            transition
                            className="px-3"
                          >
                            <PaymentHistory />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="password"
                            transition
                            className="px-3"
                            style={{ minHeight: "600px" }}
                          >
                            <ResetPassword />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="centerInfo"
                            transition
                            className="px-3"
                            style={{ minHeight: "600px" }}
                          >
                            <CenterInfo />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* footer */}
      <Footer aosSetting="disable" />
    </>
  );
};

export default Settings;
