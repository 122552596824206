import React from "react";
import { Col, Row } from "react-bootstrap";
import ProgramPost from "./ProgramPost";

const ProgramCarouselItem = (props) => {
  const { programs } = props;

  return (
    <>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12} lg={6}>
              {programs.length > 0 && <ProgramPost program={programs[0]} />}
            </Col>
            <Col xs={12} lg={6}>
              {programs.length > 1 && <ProgramPost program={programs[1]} />}
            </Col>
            <Col xs={12} lg={6}>
              {programs.length > 2 && <ProgramPost program={programs[2]} />}
            </Col>
            <Col xs={12} lg={6}>
              {programs.length > 3 && <ProgramPost program={programs[3]} />}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProgramCarouselItem;
