import { useState, useEffect, useCallback } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Selection,
} from "devextreme-react/data-grid";

const PaymentHistoryTable = ({ tableData }) => {
  const pageSizes = [10, 25, 50, 100];
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <>
      <DataGrid
        dataSource={tableData}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        hoverStateEnabled={true}
        columnHidingEnabled={windowSize > 1200 ? false : false}
      >
        <Selection mode="single" />
        <Column
          dataField="name"
          caption="活動名稱"
          dataType="string"
          width={200}
        />
        <Column
          dataField="programNo"
          caption="活動編號"
          dataType="string"
          width={150}
        />
        <Column
          dataField="accountReceivablePaymentsDate"
          caption="付款日期"
          dataType="date"
          width={100}
        />
        <Column
          dataField="accountReceivablePaymentsAmount"
          caption="已付款項"
          dataType="number"
          format="currency"
          width={80}
        />
        <Column
          dataField="accountReceivablePaymentsMethod"
          caption="付款方式"
          dataType="string"
          width={80}
        />
        <Column
          dataField="accountReceivablePaymentsReceiptSN"
          caption="收據編號"
          dataType="string"
          width={230}
        />

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>
    </>
  );
};

export default PaymentHistoryTable;
