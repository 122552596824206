import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

const Menu = ({ navClass, buttonClass }) => {
  return (
    <Nav as="ul" className={classNames("align-items-lg-center", navClass)}>
      <Nav.Item as="li">
        <NavLink
          to="/home"
          end
          className={classNames(
            "nav-link",
            ({ ...isActive }) => isActive && "active"
          )}
        >
          主頁
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink
          to="/program"
          end
          className={classNames(
            "nav-link",
            ({ ...isActive }) => isActive && "active"
          )}
        >
          活動搜尋
        </NavLink>
      </Nav.Item>
      <Nav.Item as="li">
        <NavLink
          to="/about"
          end
          className={classNames(
            "nav-link",
            ({ ...isActive }) => isActive && "active"
          )}
        >
          關於我們
        </NavLink>
      </Nav.Item>
      <Nav.Item as="li">
        <NavLink
          to="/contact"
          end
          className={classNames(
            "nav-link",
            ({ ...isActive }) => isActive && "active"
          )}
        >
          聯絡我們
          {/* 聯絡我們/成為會員 */}
        </NavLink>
      </Nav.Item>
    </Nav>
  );
};

export default Menu;
