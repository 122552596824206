import { Col, Container, Row, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

// images
import logo_hkjc from "../../assets/images/Web_HKJC_Logo.png";
import logo_elcss from "../../assets/images/Web_ELCSS-HK Logo.png";
import logo_apple from "../../assets/images/app-store.png";
import logo_google from "../../assets/images/google-play.png";

const elcss_link = "https://service.elchk.org.hk/";

const Footer = ({ aosSetting }) => {
  /*
   data-aos="fade-up"
    */
  const dataAOS =
    aosSetting === ("disable" || undefined || "") ? "" : aosSetting;
  return (
    <section
      className="section pt-lg-6 pt-3 pb-3 position-relative"
      data-aos={dataAOS}
    >
      <Container className="px-5">
        <Row className="align-items-center">
          <Col className="">
            <div className="">信義會4S會員應用程式</div>
            <Row>
              <Col>
                <Link to="https://apps.apple.com" target="_blank">
                  <img
                    src={logo_apple}
                    height="45"
                    className="mt-2 mb-4"
                    alt=""
                  />
                </Link>
              </Col>
              <Col>
                <Link to="https://play.google.com/" target="_blank">
                  <img
                    src={logo_google}
                    height="45"
                    className="mt-2 mb-4"
                    alt=""
                  />
                </Link>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={12} md={4} className="text-center">
            <p className="mt-2 fs-14">
              {new Date().getFullYear()} © ELCSS-HK. All rights reserved.
            </p>
          </Col> */}
          <Col className="text-right">
            <div className="px-3">營運機構</div>
            <Navbar.Brand href={elcss_link} target="_blank" className="logo">
              <img src={logo_elcss} height="60" className="mt-2 mb-4" alt="" />
            </Navbar.Brand>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} className="text-center">
            <p className="mt-2 fs-14">
              {new Date().getFullYear()} © ELCSS-HK. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
