import axios from "axios";
import { getToken, login } from "./AuthService";
import { toast } from "react-hot-toast";

const getApi = () => {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASEURL}`,
    timeout: 10000,
    headers: { Authorization: "Bearer " + getToken() },
  });
  return api;
};

export const postPayment = async (params) => {
  try {
    // console.log("api.postPayment", params);

    const _param = {
      ComCenterId: params?.centerId,
      BoundType: "InBound",
      IsEnabled: true,
    };
    const paymentMethodResult = await getPaymentMethod(_param);
    console.log("paymentMethodResult", paymentMethodResult);
    const payment_method = paymentMethodResult.data.map(
      (item) => item.paymentMethodType
    );
    payment_method.push("CreditCard");
    // console.log("payment_method", payment_method, payment_method.join(", "));

    // if (!isCenterContainOnlinePayment(paymentMethodResult.data)) {
    //   toast("該中心沒有網上付款服務", { icon: <FeatherIcon icon="info" /> });
    //   return;
    // }
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("target", "_blank");
    form.setAttribute("action", process.env.REACT_APP_PAYMENT_GATEWAY);
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "ar_Id");
    hiddenField.setAttribute("value", params.ar_Id);
    form.appendChild(hiddenField);
    const hiddenField1 = document.createElement("input");
    hiddenField1.setAttribute("type", "hidden");
    hiddenField1.setAttribute("name", "amount");
    hiddenField1.setAttribute("value", params.amount * 100);
    form.appendChild(hiddenField1);
    const hiddenField2 = document.createElement("input");
    hiddenField2.setAttribute("type", "hidden");
    hiddenField2.setAttribute("name", "redirect_url");
    hiddenField2.setAttribute("value", "localhost:3000");
    form.appendChild(hiddenField2);
    const hiddenField3 = document.createElement("input");
    hiddenField3.setAttribute("type", "hidden");
    hiddenField3.setAttribute("name", "payment_method");
    hiddenField3.setAttribute("value", (payment_method || []).join(", "));
    form.appendChild(hiddenField3);
    const hiddenField4 = document.createElement("input");
    hiddenField4.setAttribute("type", "hidden");
    hiddenField4.setAttribute("name", "client");
    hiddenField4.setAttribute("value", "ELCSS");
    form.appendChild(hiddenField4);
    document.body.appendChild(form);
    form.submit();
  } catch (err) {
    // Handle Error Here
    console.error(err);
    toast.error("現在未能連接到支付中心");
  }
};

export const getBanner = async () => {
  try {
    return await getApi().get(`/api/BannerInfo`);
  } catch (err) {
    // Handle Error Here
    console.error(err);
    switch (err.response.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};

export const getMemberInfo = async () => {
  try {
    return await getApi().get(`/api/MemberInfo`);
  } catch (err) {
    // Handle Error Here
    console.error(err);
    switch (err.response.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};

export const getMemberPhoto = async (id) => {
  try {
    return await getApi().get(`/api/MemberInfo/${id}/Portrait`);
  } catch (err) {
    // Handle Error Here
    switch (err.response.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};

export const getProgramInfo = async (params) => {
  try {
    // console.log("api.getProgramInfo", params);
    return await getApi().post(`/api/ProgramInfo/GetProgramInfos`, params);
  } catch (err) {
    console.error(err);
    // Handle Error Here
    switch (err.response.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};

export const getProgramCategory = async () => {
  try {
    return await getApi().get(`/api/ProgramInfo/Category`);
  } catch (err) {
    // Handle Error Here
    switch (err.response.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};

export const getProgramInfoImage = async (id) => {
  try {
    return await getApi().get(`/api/ProgramInfo/${id}/Image`);
  } catch (err) {
    // Handle Error Here
    if (err.response) {
      switch (err.response.status) {
        case 401:
          login();
          break;
        default:
          console.error(err);
      }
    } else {
      throw err;
    }
  }
};

export const getProgramInfoBanner = async (id) => {
  try {
    return await getApi().get(`/api/ProgramInfo/${id}/Banner`);
  } catch (err) {
    // Handle Error Here
    if (err.response) {
      switch (err.response.status) {
        case 401:
          login();
          break;
        default:
          console.error(err);
      }
    } else {
      throw err;
    }
  }
};

export const getPaymentMethod = async (params) => {
  try {
    return await getApi().get(`/api/PaymentMethod`, { params: params });
  } catch (err) {
    // Handle Error Here
    switch (err?.response?.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};
export const getProgramEnrollment = async (params) => {
  try {
    return await getApi().get(`/api/ProgramEnrollment`, { params: params });
  } catch (err) {
    // Handle Error Here

    switch (err?.response?.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};
export const getAccountReceivable = async (params) => {
  try {
    return await getApi().get(`/api/AccountReceivable`, { params: params });
  } catch (err) {
    // Handle Error Here

    switch (err?.response?.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};
export const getNews = async () => {
  try {
    return await getApi().get(`/api/News`);
  } catch (err) {
    // Handle Error Here
    switch (err.response.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};

export const getMemberFamilyMember = async (params) => {
  try {
    return await getApi().get(`/api/MemberFamilyMember`, { params: params });
  } catch (err) {
    // Handle Error Here
    switch (err.response.status) {
      case 401:
        login();
        break;
      default:
        console.error(err);
    }
  }
};

export const postEnrollment = async (params) => {
  try {
    return await getApi().post(`/api/ProgramEnrollment`, params);
  } catch (err) {
    // Handle Error Here
    switch (err.response.status) {
      case 400:
        throw err.response.data.errors;
      case 401:
        login();
        break;
      // window.location.href = process.env.REACT_APP_URL + "/login"

      default:
        console.error(err);
        throw err;
    }
  }
};

export const resetPassword = async (params) => {
  try {
    return await getApi().put(
      `/api/MemberInfo/${params.id}/Identity/Password`,
      params
    );
  } catch (err) {
    // Handle Error Here
    switch (err.response.status) {
      case 400:
        throw err.response.data.errors;
      case 401:
        login();
        break;
      // window.location.href = process.env.REACT_APP_URL + "/login"

      default:
        console.error(err);
        throw err;
    }
  }
};

export const postContactUs = async (params) => {
  try {
    return await getApi().post(`/api/ContactUs/`, params);
  } catch (err) {
    // Handle Error Here
    switch (err.response.status) {
      case 400:
        throw err.response.data.errors;
      case 401:
        login();
        break;
      // window.location.href = process.env.REACT_APP_URL + "/login"

      default:
        console.error(err);
        throw err;
    }
  }
};
