import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Player } from "@lottiefiles/react-lottie-player";
import moment from "moment";
import LottieLoadingFile from "assets/json/LottieLoadingFile.json";
import { getProgramEnrollment } from "../../../../services/apiCore";
import PaymentHistoryTable from "../../../../components/tables/PaymentHistoryTable";
import CenterContext from "utils/CenterContext";

const PaymentHistory = () => {
  const { center } = useContext(CenterContext);
  const [enrollments, setEnrollments] = useState();
  const [tableData, setTableData] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const pageSize = 999;

  useEffect(() => {
    function adjustFontSize() {
      const textNodes = getTextNodes(document.body);
      document.body.style.setProperty("--size", localStorage.size);
      textNodes.forEach(node => {
        const orignFontSize = parseInt(window.getComputedStyle(node.parentNode).fontSize);
      if (node.parentNode.style.getPropertyValue("--sorignFontSize") == '') {
        node.parentNode.style.setProperty("--sorignFontSize", orignFontSize + "px");
        node.parentNode.style.fontSize = `calc(var(--sorignFontSize) * var(--size))`;
      }
    });
  }
  /* getnodes */
  function getTextNodes(node) {
    let textNodes = [];
    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node);
    } else {
      const children = node.childNodes;
      if (children) {
        for (let i = 0; i < children.length; i++) {
          textNodes = textNodes.concat(getTextNodes(children[i]));
        }
      }
    }
    return textNodes;
  }
  async function fetchDataAndAdjust() {
    await fetchData();
    setTimeout(function () {
      adjustFontSize();
    }, 1000); //await
  }
  fetchDataAndAdjust();
  }, [center]);

  useEffect(() => {
    let data = [];
    (enrollments || []).map((item, index) => {
      // console.log("item", item)
      if (
        item.accountReceivable &&
        item.accountReceivable.accountReceivablePayments.length > 0
      ) {
        data.push({
          id: item.id,
          name: item.programInfo.name,
          programNo: item.programInfo.programNo,
          accountReceivableId: item.accountReceivable?.id,
          accountReceivableStatus: item.accountReceivable?.status,
          accountReceivablePaymentsDate: moment(
            item.accountReceivable?.accountReceivablePayments[0]?.paymentDate
          ).format(process.env.REACT_APP_DATE_FORMAT),
          accountReceivablePaymentsMethod:
            item.accountReceivable?.accountReceivablePayments[0]?.paymentMethod
              ?.nameZN,
          accountReceivablePaymentsAmount:
            item.accountReceivable?.accountReceivablePayments[0]?.settleAmount,
          accountReceivablePaymentsReceiptSN:
            item.accountReceivable?.accountReceivablePayments[0]
              ?.receiptSerialNumber,
        });
      }
    });
    setTableData(data);
  }, [enrollments]);

  const fetchData = async () => {
    setShowLoading(true);
    const _params = {
      PageNumber: 1,
      PageSize: pageSize,
      OrderBy: "id",
      IsOrderByAsc: false,
    };
    const _result = await getProgramEnrollment(_params);
    setEnrollments(
      _result?.data?.items
      // _result?.data?.items.filter(
      //   (item) => String(item.comCenter.id) === String(center.id)
      // )
    );
    setShowLoading(false);
  };

  const handleRefresh = () => {
    fetchData();
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <h4 className="mt-3 mt-lg-0">付款記錄</h4>
        </Col>
      </Row>

      <div className="py-2">
        <button onClick={handleRefresh} className="btn-refresh-1">
          更新
        </button>
      </div>

      {!showLoading && (
        <PaymentHistoryTable tableData={tableData}></PaymentHistoryTable>
      )}
      {!!showLoading && (
        <Player
          autoplay
          loop
          // src="https://assets8.lottiefiles.com/private_files/lf30_fup2uejx.json"
          src={LottieLoadingFile}
          style={{ height: "280px", width: "280px" }}
          className=""
        ></Player>
      )}
    </>
  );
};

export default PaymentHistory;
