import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getUserimgStorage,
  getMemberStorage,
  getCenterStorage,
} from "../../../../services/AuthService";
import moment from "moment";
// components
import { FormInput } from "../../../../components/form";
import MemberContext from "../../../../utils/MemberContext";
import CenterContext from "../../../../utils/CenterContext";
import QRCode from "react-qr-code";
import default_img from "../../../../assets/images/avatars/default.png";

const AccountInformation = () => {
  const [otherField, setotherField] = useState({
    name: "",
    email: "",
    nameZH: "",
    phone: "",
    memberNo: "",
    gender: "",
    telMobile: "",
    age: "",
    birthdate: "",
    originCenter: "",
    center: "",
    loyaltyPoint: "0",
    QRCode: "0",
    address: "",
  });
  const [img, setImg] = useState(getUserimgStorage());
  const { member } = useContext(MemberContext);
  const { center } = useContext(CenterContext);

  useEffect(() => {
    reloadData();
    setImg(getUserimgStorage());
  }, [member, center]);

  const reloadData = () => {
    const result = member;
    !!member &&
      setotherField({
        name: result.nameEN?.fullName,
        email: result.email,
        nameZH: result.nameZH?.fullName,
        phone: result.telHome,
        memberNo: result.memberNo,
        gender: result.gender == "M" ? "男" : result.gender == "F" ? "女": "不詳",
        telMobile: result.telMobile,
        age: result.age,
        birthdate: moment(result.birthdate).format(
          process.env.REACT_APP_DATE_FORMAT
        ),
        address: result.addressRemarks ? result.addressRemarks : "",
        originCenter: result.originCenter?.nameZH,
        center: result.center?.nameZH,
        loyaltyPoint: result.loyaltyPoint,
        QRCode: result.memberNo,
      });
  };

  return (
    <>
      <h4 className="mt-3 mt-lg-0">個人資料</h4>

      <form className="account-form">
        <Row className="align-items-center">
          <Col xs={4}>
            <h6 className="mt-4">頭像</h6>
            <div>
              <img
                src={img ? img : default_img}
                className="img-fluid avatar-md rounded-circle shadow"
                alt="..."
              />
            </div>
          </Col>
          <Col xs={4}>
            <h6 className="mt-4">QR Code</h6>
            <div>
              <QRCode
                size={80}
                value={otherField?.QRCode ? otherField?.QRCode : ""}
              />
            </div>
          </Col>
          <Col xs={4}>
            <h6 className="mt-4">積分</h6>
            <div>
              <h1 className="loyalty-line-height">
                {otherField?.loyaltyPoint ? otherField?.loyaltyPoint : "0"}
              </h1>
            </div>
          </Col>
        </Row>

        <hr className="my-4" />

        <Row className="align-items-center">
          <Col lg={6}>
            <FormInput
              type="text"
              label="會員編號"
              placeholder=""
              name="memberNo"
              containerClass="mb-3"
              readOnly="readonly"
              value={otherField?.memberNo ? otherField?.memberNo : ""}
            />
          </Col>
          <Col lg={6}>
            <FormInput
              type="text"
              label="中文名稱"
              placeholder=""
              name="nameZH"
              containerClass="mb-3"
              value={otherField?.nameZH ? otherField?.nameZH : ""}
              readOnly="readonly"
            />
          </Col>
          <Col lg={6}>
            <FormInput
              type="text"
              label="英文名稱"
              placeholder=""
              name="name"
              containerClass="mb-3"
              value={otherField?.name ? otherField?.name : ""}
              readOnly="readonly"
            />
          </Col>
          <Col lg={6}>
            <FormInput
              type="text"
              label="性別"
              placeholder=""
              name="gender"
              containerClass="mb-3"
              value={otherField?.gender ? otherField?.gender : ""}
              readOnly="readonly"
            />
          </Col>
          <Col lg={6}>
            <FormInput
              type="text"
              label="年齡"
              placeholder=""
              name="age"
              containerClass="mb-3"
              value={otherField?.age ? otherField?.age : ""}
              readOnly="readonly"
            />
          </Col>
          <Col lg={6}>
            <FormInput
              type="text"
              label="出生日期"
              placeholder=""
              name="birthdate"
              containerClass="mb-3"
              value={otherField?.birthdate ? otherField?.birthdate : ""}
              readOnly="readonly"
            />
          </Col>
          <Col lg={6}>
            <FormInput
              type="text"
              label="電郵地址"
              placeholder=""
              name="email"
              containerClass="mb-3"
              value={otherField?.email ? otherField?.email : ""}
              readOnly="readonly"
            />
          </Col>
          <Col lg={6}>
            <FormInput
              type="text"
              label="手提電話"
              placeholder=""
              name="telMobile"
              containerClass="mb-3"
              value={otherField?.telMobile ? otherField?.telMobile : ""}
              readOnly="readonly"
            />
          </Col>
          <Col lg={6}>
            <FormInput
              type="text"
              label="電話"
              placeholder=""
              name="phone"
              containerClass="mb-3"
              value={otherField?.phone ? otherField?.phone : ""}
              readOnly="readonly"
            />
          </Col>
          <Col lg={6}>
            <FormInput
              type="text"
              label="付款方式"
              placeholder=""
              name="defaultPaymentMethod"
              containerClass="mb-3"
              value={
                otherField?.defaultPaymentMethod
                  ? otherField?.defaultPaymentMethod
                  : ""
              }
              readOnly="readonly"
            />
          </Col>

          <Col lg={6}>
            <FormInput
              type="text"
              label="首次登記中心"
              placeholder=""
              name="originCenter"
              containerClass="mb-3"
              value={otherField?.originCenter ? otherField?.originCenter : ""}
              readOnly="readonly"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FormInput
              type="textarea"
              label="詳細地址"
              placeholder=""
              name="address"
              containerClass="mb-3"
              value={otherField?.address ? otherField?.address : ""}
              readOnly="readonly"
            />
          </Col>
        </Row>
      </form>
    </>
  );
};

export default AccountInformation;
