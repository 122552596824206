import { Carousel } from "primereact/carousel";
import ProgramCarouselItem from "./ProgramCarouselItem";

const ProgramSlider = (props) => {
  const { programs, showNavigators } = props;

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const itemTemplate = (program) => {
    return (
      <>
      <ProgramCarouselItem programs={program} />
      </>
      
    );
  };

  return (
    <>
      <Carousel
        value={programs}
        itemTemplate={itemTemplate}
        responsiveOptions={responsiveOptions}
        showNavigators={showNavigators}
      />
        
    </>
  );
};
export default ProgramSlider;
