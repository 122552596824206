import { loginRedirect, login } from '../../services/AuthService';
import { useEffect } from 'react';

const Login = ({url}) => {
    loginRedirect(url)
    
    return (<div>Redirect to login page</div>)
};

export default Login;
