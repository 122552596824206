import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import moment from "moment";
import { Table } from "rsuite";
import { sortBy } from "lodash";
import { getNews } from "../../../../services/apiCore";

const CenterInfo = () => {
  const [news, setNews] = useState();
  const [resultData, setresultData] = useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [pageSize, setpageSize] = useState(10);
  useEffect(async () => {
    const result = await getNews();
    setresultData(result.data);
  }, []);
  useEffect(() => {
    let data = [];
    (resultData || []).map((item, index) => {
      data.push({
        id: index + 1,
        title: item?.title,
        summary: item?.summary,
        content: item?.content,
        lastModified: moment(item?.lastModified).format(
          process.env.REACT_APP_DATETIME_FORMAT
        ),
      });
    });
    setNews(data);
  }, [resultData]);
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      let newData = {};
      if (sortType == "asc") {
        newData = sortBy(news, sortColumn);
      } else {
        newData = sortBy(news, sortColumn).reverse();
      }
      setNews(newData);
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };
  const handleGetMore = async () => {
    const param = { PageNumber: 1, PageSize: pageSize + 10 };
    setpageSize(pageSize + 10);
    const result = await getNews(param);
    setresultData(result?.data);
  };
  return (
    <>
      <Row>
        <Col xs={12}>
          <h4 className="mt-3 mt-lg-0">中心資訊</h4>
        </Col>
      </Row>

      <Row className="mb-12">
        <Col xs={12}>
          <Table
            autoHeight
            data={news}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={loading}
            style={{ backgroundColor: "#fff" }}
            wordWrap={true}
            rowHeight={150}
          >
            <Table.Column width={80} sortable resizable>
              <Table.HeaderCell>序號</Table.HeaderCell>
              <Table.Cell dataKey="id" />
            </Table.Column>

            <Table.Column width={120} sortable resizable>
              <Table.HeaderCell>標題</Table.HeaderCell>
              <Table.Cell dataKey="title" />
            </Table.Column>
            <Table.Column width={200} sortable resizable>
              <Table.HeaderCell>概要</Table.HeaderCell>
              <Table.Cell dataKey="summary" />
            </Table.Column>
            <Table.Column width={200} sortable resizable>
              <Table.HeaderCell>内容</Table.HeaderCell>
              <Table.Cell dataKey="content" />
            </Table.Column>
            <Table.Column width={120} sortable resizable>
              <Table.HeaderCell>修改日期</Table.HeaderCell>
              <Table.Cell dataKey="lastModified" />
            </Table.Column>
          </Table>
        </Col>
        <Col xs={12}>
          <Button variant="white" onClick={handleGetMore} hidden>
            更多
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CenterInfo;
