import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Button, Badge, Card } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import classNames from "classnames";
import ShareIcons from "../../../components/ShareIcons";
import ReactTooltip from "react-tooltip";

const Hero = ({
  program,
  img,
  sessions,
  showEnrollmentBtn,
  handleShowEnrollmentModal,
}) => {
  const navigate = useNavigate();
  const [maxPerson, setmaxPerson] = useState(0);
  const [programFees, setprogramFees] = useState();

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const handleWindowResize = (event) => {
    setWindowSize(window.innerWidth);
    setIsMobile(window.innerWidth < 992);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const handleClick = () => {
    // const path = `/program`
    // navigate(path)
    navigate(-1);
  };

  useEffect(() => {
    let tempvalue = 0;
    program?.programSessions?.map((item) => {
      tempvalue = tempvalue + item.maximunNumberOfPaticipant;
    });
    setmaxPerson(tempvalue);
    let programFeesStr = "";
    if (program?.isFree === false) {
      program?.programFees?.map((item) => {
        if (item.memberType) {
          programFeesStr =
            programFeesStr +
            item.memberType?.nameZH +
            "$" +
            item.amount +
            ";  ";
        } else {
          programFeesStr = programFeesStr + "非會員$" + item.amount + ";  ";
        }
      });
    } else if (program?.isFree === true) {
      programFeesStr = "免費";
    } else {
      programFeesStr = "";
    }
    setprogramFees(programFeesStr);
  }, [program]);

  return (
    <>
      {/* <section className="position-relative p-3 bg-gradient2"> */}
      {/* <Container> */}
      {/* <ProgramEnrollmentModal
        program={program}
        show={show}
        handleClose={handleClose}
        sessions={sessions}
      ></ProgramEnrollmentModal> */}

      <Row className="program-detail-pt">
        {!!isMobile && (
          <>
            <Col xs={6} className="p-2">
              <Button variant="outline-dark" onClick={handleClick}>
                <FeatherIcon icon="arrow-left" className="icon icon-xs me-2" />
                返回
              </Button>
            </Col>

            <Col xs={6} className="p-2 ">
              <>
                {!!showEnrollmentBtn ? (
                  <div className="text-end pe-7">
                    <Button
                      variant="info"
                      onClick={handleShowEnrollmentModal}
                      className="program-detail-fix-nav"
                    >
                      報名
                      <FeatherIcon
                        icon="user-check"
                        className="icon icon-xs ms-2"
                      />
                    </Button>
                  </div>
                ) : (
                  <h6>
                    請先 登入 / 轉換
                    <br />
                    為該中心會員才能報名
                  </h6>
                )}
              </>
            </Col>
            <Col xs={12} className="text-center">
              <h1 className="hero-title">{program?.name}</h1>
            </Col>
          </>
        )}
        {!isMobile && (
          <>
            <Col xs={3} className="p-2">
              <Button variant="outline-dark" onClick={handleClick}>
                <FeatherIcon icon="arrow-left" className="icon icon-xs me-2" />
                返回
              </Button>
            </Col>
            <Col xs={6} className="text-center">
              <h1 className="hero-title">{program?.name}</h1>
            </Col>
            <Col xs={3} className="p-2 ">
              <>
                {!!showEnrollmentBtn ? (
                  <div className="text-end pe-7">
                    <Button
                      variant="info"
                      onClick={handleShowEnrollmentModal}
                      className="program-detail-fix-nav"
                    >
                      報名
                      <FeatherIcon
                        icon="user-check"
                        className="icon icon-xs ms-2"
                      />
                    </Button>
                  </div>
                ) : (
                  <h6>
                    請先 登入 / 轉換
                    <br />
                    為該中心會員才能報名
                  </h6>
                )}
              </>
            </Col>
          </>
        )}
      </Row>

      <div className="border-top py-4 align-items-center mt-2 font-size-large">
        <Badge bg="" className={classNames("badge-soft-info", "p-2")}>
          {program?.programOnlineEnrollmentSetting?.category?.data1ZH}
        </Badge>
        <span className="px-1"> </span>
        <Badge bg="" className={classNames("badge-soft-orange", "p-2")}>
          {program?.center.nameZH}
        </Badge>
      </div>

      <div className="align-items-center font-size-large">
        <Row>
          <Col className="pb-3" lg={6} md={12}>
            {/* <FeatherIcon
              icon="calendar"
              size="35"
              className="px-2 pb-2  pointer"
              data-tip="活動日期"
            /> */}
            活動日期：
            {moment(program?.startDate).format(
              process.env.REACT_APP_DATE_FORMAT
            )}
            {" - "}
            {moment(program?.endDate).format(process.env.REACT_APP_DATE_FORMAT)}
          </Col>

          <Col className="pb-3" lg={6} md={12}>
            {/* <FeatherIcon
              icon="users"
              size="35"
              className="px-2 pb-2  pointer"
              data-tip="活動對象"
            /> */}
            活動對象：
            {program?.programObject}
          </Col>
        </Row>
        <Row>
          <Col className="pb-3" lg={6} md={12}>
            {/* <FeatherIcon
              icon="clock"
              size="35"
              className="px-2 pb-2  pointer"
              data-tip="活動時間"
            /> */}
            活動時間：
            {moment(program?.startDate).format(
              process.env.REACT_APP_TIME_FORMAT
            )}
            {"-"}
            {moment(program?.endDate).format(process.env.REACT_APP_TIME_FORMAT)}
          </Col>

          <Col className="pb-3" lg={6} md={12}>
            {/* <FeatherIcon
              icon="user-check"
              size="35"
              className="px-2 pb-2  pointer"
              data-tip="活動人數"
            /> */}
            活動人數：
            {program?.programOnlineEnrollmentSetting?.enrollmentQuota}
          </Col>
        </Row>
        <Row>
          <Col className="pb-3" lg={6} md={12}>
            {/* <FeatherIcon
              icon="map-pin"
              size="35"
              className="px-2 pb-2  pointer"
              data-tip="活動場地"
            /> */}
            活動場地：
            {program?.venue}
          </Col>

          <Col className="pb-3" lg={6} md={12}>
            {/* <FeatherIcon
              icon="credit-card"
              size="35"
              className="px-2 pb-2  pointer"
              data-tip="負責同事"
            /> */}
            負責同事：
            {(program?.programStaffs || []).map(
              (item) => item.staffUser.nameZH.fullName
            )}
          </Col>
        </Row>
        <Row>
          <Col className="pb-3" lg={6} md={12}>
            {/* <FeatherIcon
              icon="dollar-sign"
              size="35"
              className="px-2 pb-2  pointer"
              data-tip="費用"
            /> */}
            費用：
            {programFees}
          </Col>

          <Col className="pb-3" lg={6} md={12}>
            {/* <FeatherIcon
              icon="bell"
              size="35"
              className="px-2 pb-2  pointer"
              data-tip="報名時段"
            /> */}
            報名時段：
            {moment(program?.enrollmentStartDate).format(
              process.env.REACT_APP_DATE_FORMAT
            )}
            {" - "}
            {moment(program?.enrollmentEndDate).format(
              process.env.REACT_APP_DATE_FORMAT
            )}
          </Col>
        </Row>
      </div>
      <Row className="py-2">
        <Col className="pb-1 text-end" md={12}>
          <div>
            <span className="px-2">分享</span>
            <ShareIcons />
          </div>
        </Col>
      </Row>
      <Row className="py-2">
        <Col lg={12}>
          <figure className="figure w-100 text-center">
            <img
              src={img}
              alt="contentImage"
              className="figure-img img-fluid rounded pro-img-max-height-width"
            />

            <figcaption className="figure-caption text-center">
              {program?.name}
            </figcaption>
          </figure>
        </Col>
      </Row>

      {/* description */}
      <Row className="mt-5">
        <Col lg={12}>
          <div className="pe-4">
            <h3 className="py-2">簡介</h3>
            <h6>{program?.introduction}</h6>
          </div>
        </Col>
      </Row>

      <ReactTooltip />
      {/* </Container>  */}
      {/* </section> */}
    </>
  );
};

export default Hero;
